//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
$required-color:#DC6464;
$darkbg-color:#283046;
$modalHeader-color:#161D31;
$light-dark:#ABAFB5;
$semi-dark:#545b6d;
$info-color:#01CFE8;
$attach-color:#7367F0;
$site-color:#33AFB6;
$active-menu-color:#28C76F;
$sidebar-width:380px;
$aquamarine: #7FFFD4;

//desing spacing 
$content-padding: 0.888rem;
$grid-gutter-width: 0.888rem;

@media only screen and (max-width: 492px) {
    $sidebar-width: 280px;
}

  